import React, {useEffect, useRef, useState} from 'react';
import ReactHlsPlayer from "react-hls-player";
import {useSearchParams} from "react-router-dom";
import axios from "axios";
import {ReactFlvPlayer} from 'self-flv-player'
import {Button} from "antd";

const STREAM_SERVER = window._env_.STREAM_SERVER

const RawView = () => {
    const [params] = useSearchParams();
    const url = params.get("url");
    const referer = params.get("referer");
    const raw = params.get("raw");

    const playerRef = useRef();
    console.log(url, referer, raw)
    return (
        <div style={{backgroundColor: "black", height: '100vh'}}>
            <ReactHlsPlayer
                playerRef={playerRef}
                src={`http://localhost:3333/stream?url=${url}&referer=${referer}&raw=${raw}`}
                autoPlay={false}
                controls={true}
                onPlay={() => {
                    // playerRef.current.requestFullscreen()
                }}
                hlsConfig={{maxBufferLength: 180}}
                width="100%"
                height="100%"
            />
        </div>
    );
};

export default RawView;

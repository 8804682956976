import './App.css';
import {useState} from "react";
import {Avatar, ConfigProvider, Tabs} from "antd";
import {ProCard} from "@ant-design/pro-components";
import enUS from 'antd/locale/en_US';
import Vebo from "./Vebo";
import Cakhia from "./Cakhia";

function App() {

    const [provider, setProvider] = useState("vebo")
    return (
        <ConfigProvider locale={enUS}>
            <ProCard className={"main"}
                     extra={<div>
                         {/*<span style={{*/}
                         {/*    fontWeight: 800,*/}
                         {/*    fontFamily: "fantasy",*/}
                         {/*    fontSize: 18, marginRight: 4,letterSpacing:4*/}
                         {/*}}>BDTV</span>*/}
                         <img style={{width:70,marginRight: 4}}
                             src="https://see.fontimg.com/api/renderfont4/3z8d8/eyJyIjoiZnMiLCJoIjoxMzAsInciOjIwMDAsImZzIjo2NSwiZmdjIjoiIzAwMDAwMCIsImJnYyI6IiNGRkZGRkYiLCJ0IjoxfQ/QkRUVg/rooster-personal-use.png"
                             alt="BDTV"/>
                         <Avatar src={"football_512.png"} size={"large"} shape={"square"}/>
                     </div>}>
                <Tabs defaultActiveKey={provider}  onChange={setProvider} items={[
                    {key: "vebo", label: "Vebo", children: <Vebo provider={provider}/>},
                    {key: "cakhia", label: "Cakhia", children: <Cakhia provider={provider}/>},
                ]}/>
            </ProCard>
        </ConfigProvider>
    );
}

export default App;
